import { useState, useEffect } from 'react';

import './form.scss';
import DivHeading from '../DivHeading/DivHeading';

import { useTranslation } from 'react-i18next';

const MessageUsForm = (data?: any) => {
    const [ msgTopic, setMsgTopic ] = useState<any>();
    const [ msgSubject, setMsgSubject ] = useState<string>('');
    const [ name, setName ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ message, setMessage ] = useState<string>('');

    const { t } = useTranslation();
    
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const selectedTopicOption = document.getElementById('messageTopic') as HTMLSelectElement;
        const selectedTopicText = selectedTopicOption.options[selectedTopicOption.selectedIndex].text;
    
        const formData = {
            topic: selectedTopicText,
            subject: msgSubject,
            name: name,
            email: email,
            message: message
        }

        try {
            const response = await fetch('http://localhost:5000/send-email', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
      
            if (response.ok) {
                const data = await response.text();
                alert('Email sent successfully!');
            } else {
                alert(`Failed to send email. ${response.statusText}`);
                throw new Error(`Failed to send email: ${response.statusText}`);
            }
        } catch (error) {

        }
    };

    useEffect(() => {    
        if (data.data) {
            setMsgSubject(data.data.title);
            setMsgTopic(data.data.topic);
        }
    }, []);

    return (
        <section id="form" className="wrapper">
            <div className="content">
                <DivHeading
                    head={t('message_us_head')}
                    text={t('message_us_head_text')}
                    isCentered={true}
                />

                <form
                    className="contact-form"
                    onSubmit={handleSubmit}
                >
                    <div className="form-content">
                        <div className="form-item">
                            <label
                                htmlFor="messageTopic"
                                className='form-label'
                            >
                                {t('message_topic')}
                            </label>

                            <select
                                name="messageTopic"
                                id="messageTopic"
                                className="form-text-input"
                                value={msgTopic}
                                onChange={e => setMsgTopic(e.target.value)}
                                defaultValue={1}
                            >
                                <option
                                    value={1}
                                    hidden
                                >
                                    {t('msg_us_topic_default')}
                                </option>

                                <option
                                    value={2}
                                >
                                    {t('msg_us_topic_bug')}
                                </option>

                                <option
                                    value={3}
                                >
                                    {t('msg_us_topic_ingame_issue')}
                                </option>

                                <option
                                    value={4}
                                >
                                    {t('msg_us_refund_request')}
                                </option>

                                <option
                                    value={5}
                                >
                                    {t('msg_us_general_question')}
                                </option>

                                <option
                                    value={6}
                                >
                                    {t('msg_us_information_deletion')}
                                </option>
                            </select>
                        </div>

                        <div className="form-item">
                            <label
                                htmlFor="messageSubject"
                                className="form-label"
                            >
                                {t('message_subject')}
                            </label>

                            <input
                                type="text"
                                name="messageSubject"
                                id="messageSubject"
                                className="form-text-input"
                                placeholder={t('msg_subject_placeholder')}
                                value={msgSubject}
                                onChange={e => setMsgSubject(e.target.value)}
                            />
                        </div>

                        <div className="form-flex">
                            <div className="form-item">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    {t('msg_name_placeholder')}
                                </label>

                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-text-input"
                                    placeholder='John Doe'
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>

                            <div className="form-item">
                                <label
                                    htmlFor="email"
                                    className="form-label"
                                >
                                    {t('email_address')}
                                </label>

                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-text-input"
                                    placeholder='address@email.com'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        
                        <div className="form-item">
                            <label
                                htmlFor="message"
                                className="form-label"
                            >
                                {t('msg_title')}
                            </label>

                            <textarea
                                name="message"
                                id="message"
                                cols={30}
                                rows={10}
                                className="form-text-input"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                placeholder={t('msg_placeholder')}
                            >
                            </textarea>
                        </div>

                        <div className="center-form">
                            <input
                                type="submit"
                                className='btn-blue submit-btn'
                                value={t('msg_submit')}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default MessageUsForm;