import React, { useState, useEffect } from 'react';
import axios from 'axios';

import DivHeading from '../../components/DivHeading/DivHeading';
import './Game.scss';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Game = () => {
  const { t } = useTranslation();
  const [ reviewData, setReviewData ] = useState<any>([{}]);

  const location = useLocation();
  const { data } = location.state || {};
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const [ reviewIndex, setReviewIndex ] = useState<number>(1);
  const [ minRequirementsHTML, setMinRequirementsHTML] = useState('');
  const [ recRequirementsHTML, setRecRequirementsHTML] = useState('');
  const [ headerImage, setHeaderImage ] = useState<string>(`/images/games/${data.image}`);
  const [ gameScreenshots, setGameScreenshots ] = useState<any>([]);

  const appID = data.appID;

  const dataToSet: any = [];

  useEffect(() => {
    window.scrollTo(0,0);
    document.title = `Frostcloud Interactive | ${data.name}`
    
    const fetchUserData = async (userID: string, review: any) => {
      try {
        const response = await axios.post('http://localhost:5000/get-user-info', {
          userID: userID,
          appid: appID
        });

        var avatar = response.data.response.players[0].avatarfull;

        if (!avatar || response.data.response.players[0].steamid == '76561198106624608' ) {
          avatar = 'https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg';
        }

        return({
          userData: {
            userName: response.data.response.players[0].personaname,
            avatar: avatar,
            profile: response.data.response.players[0].profileurl
          },
          review: review
        });
      } catch (error) {
        console.error('Error fetching reviews: ', error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axios.post('http://localhost:5000/get-reviews', {
          steamID: appID
        });

        for (let i = 0; i < 5; i++) {
          dataToSet.push(await fetchUserData(
            response.data.reviews[i].author.steamid,
            response.data.reviews[i]
          ));
        }
      } catch (error) {
        console.error('Error fetching reviews: ', error);
      } finally {
        await setReviewData(dataToSet);
      }
    };

    const fetchSpecs = async () => {
      try {
        const response = await axios.post('http://localhost:5000/get-requirements', {
          appid: appID
        });

        setMinRequirementsHTML(response.data[appID].data.pc_requirements.minimum);
        setRecRequirementsHTML(response.data[appID].data.pc_requirements.recommended);

        setHeaderImage(response.data[appID].data.background);

        setGameScreenshots([
          response.data[appID].data.screenshots[0].path_thumbnail,
          response.data[appID].data.screenshots[1].path_thumbnail,
          response.data[appID].data.screenshots[2].path_thumbnail
        ]);
      } catch (error) {
        console.error('Error fetching reviews: ', error);
      }
    }

    fetchReviews();
    fetchSpecs();
  }, []);

  const prevReview = () => {
    reviewIndex <= 2 ? setReviewIndex(8) : setReviewIndex(reviewIndex-2);
  }

  const nextReview = () => {
    reviewIndex >= 8 ? setReviewIndex(2) : setReviewIndex(reviewIndex+2);
  }

  return (
    <>
      <div className="slide-container">
        <img src={headerImage} className="hero-image" alt={`Slide ${currentSlide + 1}`} />
        <div className="wrapper">
          <div className="content">
            <h1 className='hero-head'>
              {data.name}
            </h1>
            <p className="hero-text">
              {t(`${data.descName}`)}
            </p>
            <a
              href={data.trailer}
              rel="noreferrer nofollow noopener"
              className='btn-blue'
              target='_blank'
            >
              {t('trailer_text')}
            </a>
          </div>
        </div>
      </div>

    {reviewData && reviewData.length > 0 && (
    <>
    <div className='send wrapper'>
      <div className="content">
      <img
        src={gameScreenshots[0]}
        className='send-image'
        alt=""
      />

      <img
        src={gameScreenshots[1]}
        className='send-image'
        alt=""
      />

      <img
        src={gameScreenshots[2]}
        className='send-image'
        alt=""
      />
      </div>

    </div>

    <div className='reviews wrapper'>
      <div className="content">
        <DivHeading
          head={t('review_head')}
          isCentered={false}
        />
        <div className="review-grid">
          <button 
            className="review-arrow-btn"
            onClick={prevReview}
          >
            <img
              src="/images/arrow_black.svg"
              alt="Left arrow"
              className='arrow'
              id='left-arrow'
            />
          </button>


          <div className="review-container">
            <div className="review-top">
              <div className="r-t-left">
                <div className="r-t-l-left">
                  <img
                    src={`${reviewData[reviewIndex]?.userData?.avatar}`}
                    alt="User Avatar"
                    className="user-avatar"
                  />
                </div>

                <div className="r-t-l-right">
                  <span className="user-name">
                    {reviewData[reviewIndex]?.userData?.userName}
                  </span>

                  <span className="time-played">
                    {Math.round(reviewData[reviewIndex]?.review?.author.playtime_forever / 60)} {t('hours_played')}
                  </span>
                  
                  <a 
                    href={reviewData[reviewIndex]?.userData?.profile}
                    target='_blank'
                    rel='noreferrer noopener nofollow'
                    className='steam-profile'
                  >
                    {t('steam_prof')}
                  </a>
                </div>
              </div>

              <div className="r-t-right">
                <span className='recommended'>
                  {t('recommend')}
                </span>

                <img
                  src="/images/games/Review.svg"
                  alt="Thumbs up"
                  className="thumb-up"
                />
              </div>
            </div>

            <div className="review-bottom">
              <p className="review-text">
                "{reviewData[reviewIndex]?.review?.review}"
              </p>
            </div>
          </div>

          <button 
            className="review-arrow-btn"
            onClick={nextReview}
          >
            <img
              src="/images/arrow_black.svg"
              alt="Right arrow"
              className='arrow'
              id='right-arrow'
            />
          </button>
        </div>


        <div className="system-requirements">
          <DivHeading
            isCentered={false}
            head={t('required_specs')}
          />

          <div className="requirement-grid">
            <div
              dangerouslySetInnerHTML={{ __html: minRequirementsHTML }} 
            />

            <div
              dangerouslySetInnerHTML={{ __html: recRequirementsHTML }} 
            />
          </div>
        </div>
      </div>
    </div>

    </>

    )}
    </>

  );
}

export default Game;
