import { useEffect } from "react";

import Landing from "./elements/landing/landing";
import RecentGames from "./elements/recentGames/recentGames";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0,0);
        document.title = 'Frostcloud Interactive | Home'
    }, []);

    return (

    <>

    <Landing/>

    <RecentGames/>

    </>

    );
}

export default Home;