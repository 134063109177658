import './hero.scss';

import { useTranslation } from 'react-i18next';

const Hero = () => {
    const { t } = useTranslation();

    return (

    <section id="hero" className='wrapper'>
        <img
            src="/images/careers_hero.svg"
            alt="Hero Image"
            className='hero-image'
        />

        <div className="content">
            <h1 className="hero-head">
                {t('careers_head')}
            </h1>

            <p className="hero-text">
                {t('careers_text')}
            </p>
        </div>
    </section>

    );
}

export default Hero;