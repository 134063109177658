import './news.scss';
import DivHeading from "../../components/DivHeading/DivHeading";
import Landing from './elements/landing/landing';
import Updates from './elements/updates/updates';

import { useTranslation } from 'react-i18next';

const Content = () => {
    const { t } = useTranslation();

    return (

    <section
        id="news"
        className="wrapper"
    >
        <div className="content">
            <DivHeading
                head={t('header_news')}
                isCentered={false}
            />

            <Landing/>

            <DivHeading
                head={t('news_updates')}
                isCentered={false}
            />

            <Updates/>
        </div>
    </section>

    );
}

export default Content;