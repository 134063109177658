import React, { useState, useEffect } from 'react';

import './AutoBackground.scss';

const AutoBackground = () => {
  const imagePaths = [
    '/images/games/game1.svg',
    '/images/games/game2.svg',
    '/images/games/game3.svg'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [ prevIndex, setPrevIndex ] = useState(0);
  const [ imagePath, setImagePath] = useState(imagePaths[0]);
  const [ prevState, setPrevState ] = useState('hidden');
  const [ curState, setCurState ] = useState('visible');

  const changeBackground = () => {
    const newIndex = (currentIndex + 1) % imagePaths.length;
    setPrevIndex(currentIndex);
    prevState == 'hidden' ? setPrevState('visible') : setPrevState('hidden');
    curState == 'hidden' ? setCurState('visible') : setCurState('hidden');
    setCurrentIndex(newIndex);
    setImagePath(imagePaths[newIndex]);
  };

  useEffect(() => {
    const intervalId = setInterval(changeBackground, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
  <>

  <img
    src={`${imagePaths[currentIndex]}`}
    className={`hero-image ${curState}`}
    alt="Hero Image"
  />

  <img
    src={`${imagePaths[currentIndex]}`}
    className={`hero-image ${prevState}`}
    alt="Hero Image"
  />
  
  </>
  );
};

export default AutoBackground;