import { useEffect } from 'react';

import Content from './Content';

import './faq.scss';

const Faq = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Frequently Asked Questions';
        window.scrollTo(0,0);
    }, []);
    
    return (

    <>

    <Content/>
    
    </>
    
    );
}

export default Faq;