import { useLocation } from 'react-router-dom';
import UpdateContent from './content';

const Update = () => {
    const location = useLocation();
    const { data } = location.state || {};

    return (
    
    <>

    <UpdateContent data={data}/>
    
    </>

    );
}

export default Update;