import './recentGames.scss';

import DivHeading from '../../../../components/DivHeading/DivHeading';

import GameComponent from '../../../../components/gameComponent/gameComponent';

import { useTranslation } from 'react-i18next';

const RecentGames = () => {
    const { t } = useTranslation();

    return (

    <section
        id="recent-games"
        className='wrapper'
    >
        <div className="content">
            <DivHeading
                head={`${t('recent_games')}`}
                isCentered={false}
            />

            <div className="game-grid">
                <GameComponent
                    name='Call Of Duty: Modern Warfare 3'
                    description={`${t('mw3_description')}`}
                    image='mw3.jfif'
                    id={1}
                    descName='mw3_description'
                    trailer='https://www.youtube.com/watch?v=mHDEDDrGYvo'
                    appID={1938090}
                />

                <GameComponent
                    name="Assassin's Creed Mirage"
                    description={`${t('assassins_creed_description')}`}
                    image='assassins_creed.webp'
                    id={2}
                    descName='assassins_creed_description'
                    trailer='https://www.youtube.com/watch?v=IRNOoOYVn80'
                />

                <GameComponent
                    name='Rocket League'
                    description={`${t('rocket_league_description')}`}
                    image='rocket_league.jpg'
                    id={3}
                    descName='rocket_league_description'
                    trailer='https://www.youtube.com/watch?v=OmMF9EDbmQQ'
                    appID={252950}
                />
            </div>

            <DivHeading
                head={`${t('coming_soon')}`}
                isCentered={false}
            />

            <div className="game-grid">
                <GameComponent
                    name='Wizard Tower'
                    description={`${t('wizard_tower_description')}`}
                    image='wizard_tower.png'
                    id={4}
                    descName='wizard_tower_description'
                    trailer='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                />
            </div>
        </div>
    </section>

    );
}

export default RecentGames;