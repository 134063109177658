// Footer.tsx
import React, { useState } from 'react';
import './Footer.scss';

import axios from 'axios';

import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

import { Link } from 'react-router-dom';

const Footer = () => {
  const [showDropdown, setShowDropdown] = useState(true);
  const [showSupportDropdown, setShowSupportDropdown] = useState(true);
  const [showPoliciesDropdown, setShowPoliciesDropdown] = useState(true);
  const [showContactInfoDropdown, setShowContactInfoDropdown] = useState(true);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(true);
  const [ newsletterDropdown, setNewsletterDropdown ] = useState(true);
  

  const toggleLinksDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleSupportDropdown = () => {
    setShowSupportDropdown(!showSupportDropdown);
  };

  const togglePoliciesDropdown = () => {
    setShowPoliciesDropdown(!showPoliciesDropdown);
  };

  const toggleContactInfoDropdown = () => {
    setShowContactInfoDropdown(!showContactInfoDropdown);
  };

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };
  
  const toggleNewsletterDropdown = () => {
    setNewsletterDropdown(!newsletterDropdown);
  }

  const createDropdownButton = (
    onClick: () => void,
    isOpen: boolean
  ) => (
    <button className={`popup-toggler arrow ${isOpen}`} onClick={onClick}>
      <img src="/images/arrow_white.svg" alt="" />
    </button>
  );

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const [ email, setEmail ] = useState<string>('');

  const handleNewsletterSubmit = () => {
    const apiKey = '82818c54-9fcb-4e67-befd-b9bdd604b014';
    const apiUrl = 'http://localhost:5000/execute-query';

    axios.post(apiUrl, {
      email: email,
    }, {
      auth: {
        username: 'wssbfsvo',
        password: apiKey,
      },
    })
    .then((response: { data: any })  => {
      alert('Signed up succesfully!');
    })
    .catch((error: { response: { data: any } }) => {
      alert('Failed to sign up for the newsletter.');
      console.error('Error:', error.response.data);
    });
  }

  const { t } = useTranslation();

  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
  }

  return (
    <footer className='footer wrapper'>
      <div className='footer-section content'>
        <div className='links'>
          <div className='esimerkki-class'>
            <h4>{t('footer_links')}</h4>
            {createDropdownButton(toggleLinksDropdown, showDropdown)}
          </div>
          <ul className={showDropdown ? 'show' : ''}>
            <li><Link to="/">{t('header_home')}</Link></li>
            <li><Link to="/games">{t('header_games')}</Link></li>
            <li><Link to="/deals">{t('header_deals')}</Link></li>
            <li><Link to="/news">{t('header_news')}</Link></li>
            <li><Link to="/contact">{t('header_contact')}</Link></li>
          </ul>
        </div>
        <div className='support'>
          <div className='esimerkki-class'>
            <h4>{t('header_support')}</h4>
            {createDropdownButton(toggleSupportDropdown, showSupportDropdown)}
          </div>
          <ul className={showSupportDropdown ? 'show' : ''}>
            <li><Link to="/faq">{t('header_faq')}</Link></li>
            <li><Link to="/message-us">{t('header_message_us')}</Link></li>
            <li><Link to="/refund-policy">{t('refund_policy')}</Link></li>
          </ul>
        </div>
        <div className='policies'>
          <div className='esimerkki-class'>
            <h4>{t('policies')}</h4>
            {createDropdownButton(togglePoliciesDropdown, showPoliciesDropdown)}
          </div>
          <ul className={showPoliciesDropdown ? 'show' : ''}>
            <li><Link to="/terms-of-service">{t('terms_of_service')}</Link></li>
            <li><Link to="/privacy-policy">{t('privacy_policy')}</Link></li>
            <li><Link to="/legal-info">{t('legal_info')}</Link></li>
            <li><Link to="/eula" rel='noreferrer noopener nofollow'>EULA</Link></li>
          </ul>
        </div>
        <div className="newsletter">
          <div className="esimerkki-class newsletter-wrapper">
            <div className="top">
              <h4>{t('newsletter')}</h4>
              {createDropdownButton(toggleNewsletterDropdown, newsletterDropdown)}
            </div>
            <div className={`bottom ${newsletterDropdown ? 'show' : ''}`}>
              <span>
                {t('newsletter_text')}
              </span>

              <div className="newsletter-container" >
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder={t('email_address')}
                  className='newsletter-input'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <button
                  className='newsletter-submit'
                  onClick={handleNewsletterSubmit}
                >
                  {t('sign_up')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='contact-info'>
          <div className='esimerkki-class'>
            <h4>{t('contact_info')}</h4>
            {createDropdownButton(toggleContactInfoDropdown, showContactInfoDropdown)}
          </div>
          <ul className={showContactInfoDropdown ? 'show' : ''}>
            <li>
              <Link 
                to="https://www.linkedin.com/"
                target='_blank'
                rel='noreferrer noopener nofollow'
              >
                LinkedIn
              </Link>
            </li>

            <li>
              <Link 
                to="https://twitter.com/home"
                target='_blank'
                rel='noreferrer noopener nofollow'
              >
                Twitter
              </Link>
            </li>

            <li>
              <Link 
                to="https://www.instagram.com/"
                target='_blank'
                rel='noreferrer noopener nofollow'
              >
                Instagram
              </Link>
            </li>

            <li>
              <Link 
                to="https://www.youtube.com/"
                target='_blank'
                rel='noreferrer noopener nofollow'
              >
                Youtube
              </Link>
            </li>

            <li>
              <Link 
                to="https://www.discord.com/"
                target='_blank'
                rel='noreferrer noopener nofollow'
              >
                Discord
              </Link>
            </li>

          </ul>
        </div>
        <div className='Language'>
        <div className='esimerkki-class'>
            <h4>{t('language')}</h4>
            {createDropdownButton(toggleLanguageDropdown, showLanguageDropdown)}
          </div>
          <ul className={showLanguageDropdown ? 'show' : ''}>
            <li><button className="language-btn" onClick={() => changeLanguage('en')}>English</button></li>
            <li><button className="language-btn" id="l2" onClick={() => changeLanguage('fi')}>Finnish</button></li>
          </ul>
      </div>
      </div>
      <div className='copyright'>
        <p>&copy; 2024 {t('copyright')}</p>
      </div>
    </footer>
  );
};

export default Footer;