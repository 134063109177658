import '../notfound.scss';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Content = () => {
    const { t } = useTranslation();

    return (

    <section
        id="not-found"
        className="wrapper"
    >
        <div className="content">
            <h2 className="not-found-head">
                Oops!
            </h2>

            <span className="not-head-small">
                404 - {t('404_page_head')}
            </span>

            <span className="not-head-long">
                {t('404_text')}
            </span>

            <Link
                to="/"
                rel="noreferrer nofollow noopener"
                className='btn-blue'
            >
                {t('homepage_return')}
            </Link>
        </div>
    </section>

    );
}

export default Content;