import React from 'react';

import '../css/App.scss';
import '../css/colors.css';

import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import Home from './pages/home/home';
import Game from './pages/Game-page/Game';
import Careers from './pages/careers/careers';
import NotFound from './pages/404/NotFound';
import MessageUs from './pages/messageUs/messageUs';
import ContactUs from './pages/contactUs/contactUs';
import Faq from './pages/faq/Faq';
import Eula from './pages/policies/eula/eula';
import Tos from './pages/policies/tos/tos';
import PrivacyPolicy from './pages/policies/privacyPolicy/privacyPolicy';
import LegalInfo from './pages/policies/legalInfo/legalInfo';
import News from './pages/news/news';
import Update from './pages/update/update';
import Games from './pages/games/Games';
import About from './pages/About/About';
import RefundPolicy from './pages/policies/refundPolicy/refundPolicy';
import Deals from './pages/Deals/Deals';

function App() {
    return (
    <Routes>
        <Route
            path='/'
            element={<Home/>}
        />

        <Route
            path='/game/*'
            element={<Game/>}
        />

        <Route
            path='/careers'
            element={<Careers/>}
        />

        <Route
            path='/terms-of-service'
            element={<Tos/>}
        />

        <Route
            path='/eula'
            element={<Eula/>}
        />

        <Route
            path='/privacy-policy'
            element={<PrivacyPolicy/>}
        />

        <Route
            path='legal-info'
            element={<LegalInfo/>}
        />

        <Route
            path='message-us'
            element={<MessageUs/>}
        />

        <Route
            path='contact'
            element={<ContactUs/>}
        />

        <Route
            path='/faq'
            element={<Faq/>}
        />

        <Route
            path='/news'
            element={<News/>}
        />

        <Route
            path='/update/:id'
            element={<Update/>}
        />

        <Route
            path='/games'
            element={<Games/>}
        />

        <Route
            path='/About'
            element={<About/>}
        />

        <Route
            path='/refund-policy'
            element={<RefundPolicy/>}
        />

        <Route
            path='/deals'
            element={<Deals/>}
        />

        {/* tää not found page sitte aina alimmaksi Routeksi */}
        <Route
            path='/*'
            element={<NotFound/>}
        />
    </Routes>
    );
}

export default App;
