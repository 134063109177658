import '../policies.scss';

import { Link } from 'react-router-dom';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const RefundPolicy = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Refund Policy';
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
    <>

        <section className="wrapper policy-wrapper">
            <div className="content policy-content">
                <div className="policy-item">
                    <h1 className="policy-head-main">
                        {t('refund_policy')}, {t('month_december')} 15, 2023
                    </h1>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('steam_refunds_head')}
                    </h2>

                    <span className="policy-text">
                        {t('steam_purchases_refund')}
                        <Link to="https://store.steampowered.com/steam_refunds" target='_blank' rel="noreferrer noopener nofollow" className="clean-link">{t('steam_refund_policy_text')}</Link>.
                    </span>

                    <span className="policy-text">
                        <span className="white">
                            {t('steam_playtime')}
                        </span> {t('steam_playtime_text')}
                    </span>

                    <h2 className="policy-head">
                        {t('vac_status_head')}
                    </h2>

                    <span className="policy-text">
                        {t('vac_status_text')}
                    </span>

                    <h2 className="policy-head">
                        {t('requesting_refund')}
                    </h2>

                    <span className="policy-text">
                        {t('requesting_refund_text')}
                        <Link to="https://help.steampowered.com" className='clean-link' rel="noreferrer nofollow noopener" target='_blank'>help.steampowered.com</Link>.
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('outside_steam_head')}
                    </h2>

                    <span className="policy-text">
                        {t('outside_steam_head_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('content_not_eligible_head')}
                    </h2>

                    <span className="policy-text">
                        <ul className='policy-list'>
                            <li>
                                {t('not_eligible_1')}
                            </li>

                            <li>
                                {t('not_eligible_2')}
                            </li>

                            <li>
                                {t('not_eligible_3')}
                            </li>
                        </ul>
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('other_issues_head')}
                    </h2>

                    <span className="policy-text">
                        {t('other_issues_text')}
                        <Link to="/faq" className="clean-link" rel="noreferrer nofollow noopener">{t('other_issues_faq')}</Link> {t('or')} <Link to="/message-us" className="clean-link" rel="noreferrer nofollow noopener">{t('message_us_small')}</Link>
                    </span>
                </div>
            </div>
        </section>
    </>
    );
}

export default RefundPolicy;