import { useLocation}  from 'react-router-dom';
import MessageUsForm from "../../components/ContactForm/MessageUsForm";

import { useEffect } from 'react';

const MessageUs = () => {
    const location = useLocation();
    const { yourData } = location.state || {};

    useEffect(() => {
        document.title = 'Frostcloud Interactive | Message Us';
        window.scrollTo(0,0);
    });

    return (

    <>

    <MessageUsForm data={yourData}/>

    </>
    
    );
}

export default MessageUs;