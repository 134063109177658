import { useEffect } from 'react';

import './update.scss';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const UpdateContent = (data: any) => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
        <section id="update-content" className="wrapper">
            <div className="content">
                <div className="update-hero">
                    <div className="hero-top">
                        <img
                            src={`/images/games/${data.data.image}`}
                            alt="Hero Image"
                            className="update-img"
                        />
                    </div>

                    <div className="hero-bottom">
                        <h1 className="update-head">
                            {data.data.name}
                        </h1>

                        <div className="h-b-full-width">
                            <span className="hero-update-date">
                                {data.data.newDate}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="update-change-log">
                    <h3 className="change-log-head">
                        {data.data.description}
                    </h3>

                    <div
                        className='change-log-data'
                        dangerouslySetInnerHTML={{ __html: data.data.changelog }}
                    />
                </div>

                <Link
                    to="/news"
                    className='clean-link low-weight'
                    rel='noreferrer noopener nofollow'
                >
                    {t('go_back_to_news')}
                </Link>
            </div>
        </section>
    );
}

export default UpdateContent;