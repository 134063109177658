import { useEffect } from 'react';

import '../policies.scss';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const LegalInfo = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Legal Info';
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
    <>

        <section className="wrapper policy-wrapper">
            <div className="content policy-content">
                <div className="policy-item">
                    <h1 className="policy-head-main">
                        {t('legal_info')}, {t('month_november')} 16, 2023
                    </h1>
                </div>

                <div className="policy-item">
                    <span className="policy-text white">
                        {t('legal_info_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('use_of_services')}
                    </h2>

                    <span className="policy-text">
                        {t('use_of_services_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('intellectual_property')}
                    </h2>

                    <span className="policy-text">
                        {t('intellectual_property_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('privacy_policy')}
                    </h2>

                    <span className="policy-text">
                        {t('privacy_policy_text')} <Link to="/privacy-policy" className='clean-link' rel='noreferrer nofollow noopener'>{t('in_privacy_policy')}</Link>.
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('limitation_of_liability')}
                    </h2>

                    <span className="policy-text">
                        {t('limitation_of_liability_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('governing_law')}
                    </h2>

                    <span className="policy-text">
                        {t('legal_info_governing_law_text')}
                    </span>
                </div>
            </div>
        </section>
    </>
    );
}

export default LegalInfo; 