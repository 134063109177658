import './gameComponent.scss';

import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface GameProps {
    name: string;
    image: string;
    description: string;
    id: number;
    descName?: string;
    trailer?: string;
    appID?: number;
}

const GameComponent = (props: GameProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { name, image, description, id, descName, trailer, appID } = props;

    return (
        <div className="game">
            <img
                src={`/images/games/${image}`}
                alt=""
                className="game-image"
                loading='lazy'
            />

            <div className="game-bottom">
                <span className="game-name">
                    {name}
                </span>

                <span className="description">
                    {description}
                </span>

                <Link
                    to={`/game/${id}`}
                    rel="noreferrer nofollow noopener"
                    className='btn-blue small'
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(
                            `/game/${id}`,
                            { state: {
                                data: {
                                    name,
                                    image,
                                    description,
                                    descName,
                                    trailer,
                                    appID
                                }
                            }}
                        );
                    }}
                >
                    {t('read_more')}
                </Link>
            </div>
        </div>
    );
}

export default GameComponent;