import { useEffect } from 'react';

import '../policies.scss';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Privacy Policy';
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
    <>

        <section className="wrapper policy-wrapper">
            <div className="content policy-content">
                <div className="policy-item">
                    <h1 className="policy-head-main">
                        {t('privacy_policy')}, {t('month_november')} 16, 2023
                    </h1>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('info_collected')}
                    </h2>

                    <span className="policy-text">
                        <span className="white">{t('personal_info')}</span><span> {t('personal_info_text')}</span>
                    </span>

                    <span className="policy-text">
                        <span className="white">{t('non_personal_info')}</span><span> {t('non_personal_info_text')}</span>
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('how_used')}
                    </h2>

                    <span className="policy-text white">
                        {t('how_used_text')}
                    </span>

                    <span className="policy-text">
                        {t('how_used_1')}
                    </span>

                    <span className="policy-text">
                        {t('how_used_2')}
                    </span>

                    <span className="policy-text">
                        {t('how_used_3')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('cookies')}
                    </h2>

                    <span className="policy-text">
                        {t('cookie_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('data_security')}
                    </h2>

                    <span className="policy-text">
                        {t('data_security_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('third_party_links')}
                    </h2>

                    <span className="policy-text">
                        {t('third_party_links_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('your_rights')}
                    </h2>

                    <span className="policy-text">
                        {t('your_rights_text')}<Link to="/message-us" className='clean-link' rel='noreferrer nofollow noopener'>{t('privacy_message')}</Link>.
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('changes')}
                    </h2>

                    <span className="policy-text">
                        {t('changes_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <span className="policy-text">
                        {t('policy_end_text')}
                    </span>
                </div>
            </div>
        </section>
    </>
    );
}

export default PrivacyPolicy;