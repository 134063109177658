import React, { useEffect } from 'react';
import "./About.scss"
import DivHeading from '../../components/DivHeading/DivHeading';

import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0,0);
        document.title = 'Frostcloud Interactive | About'
    }, []);

    return (
        <div>
            <div className="OEE">
                <div className="hero-container">
                    <img
                        className="hero-image"
                        src='/images/Linna.svg'
                    />
                    <div className="About-content">
                        <div className="text-container">
                            <h1 className='hero-head1'>
                                {t('about_head')}
                            </h1>
                            <p className='About-text'>"{t('home_landing_text')}" - Frostcloud Interactive</p>
                        </div>
                    </div>
                </div>           
            </div>
            <div className='our-story'>    
                <DivHeading
      head={t('our_story')}
      isCentered={false}/>
      <p>{t('story_text')}</p>
      
      <img
                        className="story-image"
                        src='/images/About_img.svg'
                    />
      <p>{t('story_text_journey')}
</p>
<p>{t('story_text_3')}
</p>
<p>{t('story_text_4')}
</p>
<p>
    {t('story_text_5')}
</p>
<p>{t('story_text_6')}
</p>
      </div>
        </div>
    );
}

export default About;
