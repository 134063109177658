import { useEffect } from 'react';

import './games.scss';

import RecentGames from "../home/elements/recentGames/recentGames";

const Games = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (

    <>

    <div id="content">
        <RecentGames/>
    </div>
    
    </>

    );
}

export default Games;