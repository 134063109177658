import { useEffect } from "react";
import ContactUsForm from "../../components/ContactForm/contactUsForm";

import { useLocation } from 'react-router-dom';

const ContactUs = () => {
    const location = useLocation();
    const { yourData } = location.state || {};

    useEffect(() => {
        document.title = 'Frostcloud Interactive | Contact Us';
        window.scrollTo(0,0);
    }, []);

    return (

    <>

    <ContactUsForm data={yourData}/>

    </>
    
    );
}

export default ContactUs;