import axios from 'axios';

import './landing.scss';

import AutoBackground from './AutoBackground/AutoBackground';

import { useTranslation } from 'react-i18next';

const Landing = () => {
    const { t } = useTranslation();

    return (

    <section
        id="landing"
        className='wrapper'
    >
        <AutoBackground/>

        <div className="content">
            <h1
                className='hero-head'
            >
                Frostcloud interactive
            </h1>

            <p
                className="hero-text"
            >
                {t('home_landing_text')}
            </p>

            <a
                href="#recent-games"
                rel="noreferrer nofollow noopener"
                className='btn-blue'
            >
                {t('explore_btn_text')}
            </a>
        </div>
    </section>

    );
}

export default Landing;