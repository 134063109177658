import { useEffect, useState } from 'react';
import DivHeading from '../../components/DivHeading/DivHeading';

import axios from 'axios';

import './Deals.scss';

import { useTranslation } from 'react-i18next';

import {
    Steam,
    Twitter,
    Youtube,
    Instagram,
    Discord,
    Linkedin
} from 'react-bootstrap-icons';

const Deals = () => {
    useEffect(() => {
        window.scrollTo(0,0);
        document.title = 'Frostcloud Interactive | Deals'
    }, []);

    const [ email, setEmail ] = useState<string>('');

    const handleNewsletterSubmit = () => {
      const apiKey = '82818c54-9fcb-4e67-befd-b9bdd604b014';
      const apiUrl = 'http://localhost:5000/execute-query';
  
      axios.post(apiUrl, {
        email: email,
      }, {
        auth: {
          username: 'wssbfsvo',
          password: apiKey,
        },
      })
      .then((response: { data: any })  => {
        alert(response);
      })
      .catch((error: { response: { data: any } }) => {
        alert('Failed to sign up. ' + error.response.data);
        console.error('Error:', error.response.data);
      });
    }

    const { t } = useTranslation();

    interface DealProps {
        gameName: string;
        sale: number;
        steamUrl: string;
        img: string;
        steamText: string;
    }

    const Deal = (props: DealProps) => {
        const { gameName, sale, steamUrl, img, steamText } = props;

        return (
            <div className="deal">
                <img
                    src={`/images/games/${img}`}
                    alt="Background"
                    className="deal-bg-image"
                />

                <div className="deal-content">
                    <div className="deal-top">
                        <span className="deal-game-name">
                            {gameName}
                        </span>
                    </div>

                    <div className="deal-bottom">
                        <a
                            href={steamUrl}
                            rel='noreferrer noopener nofollow'
                            className='steam-link'
                            target='_blank'
                        >
                            <Steam className='steam-icon'/>

                            <span className="steam-text">
                                {steamText}
                            </span>
                        </a>

                        <span className="sale">- {sale}%</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>

        <section className="infinite-scroll-text">
            <div className="scroll-text">
                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>

                <span className="text-scrolling">
                    {t('game_deal_text_1')} <span className="blue">- 50%</span> {t('game_deal_season')}
                </span>
            </div>
        </section>

        <section id="deal-grid-wrapper" className="wrapper">
            <div className="deal-grid content">
                <Deal
                    gameName="Rocket League"
                    steamUrl="https://store.steampowered.com/app/252950/Rocket_League/"
                    sale={50}
                    img='rocket_league.jpg'
                    steamText={t('deals_visit_steam')}
                />

                <Deal
                    gameName="Modern Warfare 3"
                    steamUrl="https://store.steampowered.com/agecheck/app/2519060/"
                    sale={30}
                    img='mw3.jfif'
                    steamText={t('deals_visit_steam')}
                />

                <Deal
                    gameName="Wizard Tower"
                    steamUrl="https://steamcommunity.com/app/252950"
                    sale={25}
                    img='wizard_tower.png'
                    steamText={t('deals_preorder')}
                />
            </div>
        </section>

        <a
            id="steam-release"
            className="wrapper"
            href='https://store.steampowered.com/app/252950/Rocket_League/'
            target='_blank'
            rel='noreferrer noopener nofollow'
        >
            <img
                src="/images/steam.jpg"
                alt=""
                className='steam-release-bg'
            />

            <div className="contentti">
                <span className="steam-release-text">
                    {t('deals_release_page')}
                </span>
            </div>
        </a>

        <section id="future-updates" className="wrapper">
            <div className="content">
                <h2 className="future-deal-head">
                    {t('deals_get_notified')}
                </h2>

                <div className="newsletter-wrapper">
                    <span className="newsletter-head">
                        {t('deals_signup_text')}
                    </span>

                    <div className="newsletter-form-container">
                        <input
                            type="text"
                            name="newsletter"
                            id="newsletter"
                            className='newsletter-form-input'
                            placeholder={t('email_address')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <button
                            className="sign-up-btn"
                            onClick={handleNewsletterSubmit}
                        >
                            {t('deals_signup_btn')}
                        </button>
                    </div>
                </div>

                <h2 className="social-media-head">
                    {t('deals_follow_social')}
                </h2>

                <div className="social-media-grid">
                    <a
                        href="https://twitter.com/home"
                        className="social-media"
                        target='_blank'
                        rel='noreferrer noopener nofollow'
                    >
                        <Twitter
                            className='social-media-icon'
                        />
                    </a>

                    <a
                        href="https://www.youtube.com/"
                        className="social-media"
                        target='_blank'
                        rel='noreferrer noopener nofollow'
                    >
                        <Youtube
                            className='social-media-icon'
                        />
                    </a>

                    <a
                        href="https://www.instagram.com/"
                        className="social-media"
                        target='_blank'
                        rel='noreferrer noopener nofollow'
                    >
                        <Instagram
                            className='social-media-icon'
                        />
                    </a>

                    <a
                        href="https://www.discord.com/"
                        className="social-media"
                        target='_blank'
                        rel='noreferrer noopener nofollow'
                    >
                        <Discord
                            className='social-media-icon'
                        />
                    </a>

                    <a
                        href="https://www.linkedin.com/"
                        className="social-media"
                        target='_blank'
                        rel='noreferrer noopener nofollow'
                    >
                        <Linkedin
                            className='social-media-icon'
                        />
                    </a>
                </div>
            </div>
        </section>

        </>
    );
}

export default Deals;