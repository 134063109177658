import { useEffect } from 'react';

import '../policies.scss';

import { useTranslation } from 'react-i18next';

const Eula = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | EULA';
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
    <>

        <section className="wrapper policy-wrapper">
            <div className="content policy-content">
                <div className="policy-item">
                    <h1 className="policy-head-main">
                        {t('eula_head')} (EULA), {t('month_november')} 16, 2023
                    </h1>
                </div>

                <div className="policy-item">
                    <span className="policy-text white">
        	            {t('eula_head_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('licence_grant')}
                    </h2>

                    <span className="policy-text">
                        {t('licence_grant_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('restrictions')}
                    </h2>

                    <span className="policy-text white">
                        {t('restrictions_head')}
                    </span>

                    <span className="policy-text">
                        {t('restriction_1')}
                    </span>

                    <span className="policy-text">
                        {t('restriction_2')}
                    </span>

                    <span className="policy-text">
                        {t('restriction_3')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('intellectual_property')}
                    </h2>

                    <span className="policy-text">
                        {t('intellectual_property_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('warranty_disclaimer')}
                    </h2>

                    <span className="policy-text">
                        {t('warranty_disclaimer_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('limitation_of_liability')}
                    </h2>

                    <span className="policy-text">
                        {t('limitation_of_liability_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('governing_law')}
                    </h2>

                    <span className="policy-text">
                        {t('governing_law_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('termination')}
                    </h2>

                    <span className="policy-text">
                        {t('termination_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('entire_agreement')}
                    </h2>

                    <span className="policy-text">
                        {t('entire_agreement_text')}
                    </span>
                </div>
            </div>
        </section>
    </>
    );
}

export default Eula;