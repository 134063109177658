import './landing.scss';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Landing = () => {
    const { t } = useTranslation();

    return (
        <section id="news-landing" className="content">
            <img
                src="/images/careers_hero.svg"
                alt=""
                className="landing-background"
            />

            <div className="content">
                <h2 className="l-hiring-head">
                    "{t('get_hired_text')}"
                </h2>

                <Link
                    to="/careers"
                    className='btn-blue small'
                    rel='noreferrer noopener nofollow'
                >
                    {t('join_now')}
                </Link>
            </div>
        </section>
    );
}

export default Landing;