import { useState } from 'react';

import { BrightnessHigh, BrightnessHighFill } from 'react-bootstrap-icons';

import './header.scss';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Header = () => {
    const [ isSupportOpen, setIsSupportOpen ] = useState(false);
    const [ hamburgerState, setHamburgerState ] = useState('hidden');

    const handleSupportClick = () => {
        setIsSupportOpen(!isSupportOpen);
    }

    const hamburgerClick = () => {
        hamburgerState == 'hidden' ? setHamburgerState('visible') : setHamburgerState('hidden');
        const bodyState = hamburgerState == 'hidden' ? 'visible' : 'hidden';
        document.body.className=`dark-mode ${bodyState}`
    }

    const [ darkMode, setDarkMode ] = useState<boolean>(true);
    const [ darkModeIcon, setDarkModeIcon ] = useState<any>(<BrightnessHighFill/>);
    document.body.classList.add(`${darkMode}`);

    const ToggleDarkMode = () => {
        document.body.classList.remove(`${darkMode}`);
        setDarkMode(!darkMode);
        darkMode ? setDarkModeIcon(<BrightnessHigh/>) : setDarkModeIcon(<BrightnessHighFill/>);
    }

    const { t } = useTranslation();
    
    return (
    <header
        id="header"
        className={`wrapper ${hamburgerState}`}
    >
        <nav
            className="content"
            id='nav'
        >
            <ul className="nav-list">
                <li className="nav-item">
                    <Link
                        to="/"
                        rel="noreferrer noopener nofollow"
                        className="nav-link logo"
                    >
                        <img
                            src="/images/logo.png"
                            alt=""
                            className="logo-img"
                        />
                    </Link>
                </li>

                <li className="nav-item mobile-list-item">
                    <Link
                        to="/games"
                        rel="noreferrer noopener nofollow"
                        className="nav-link"
                    >
                        {t('header_games')}
                    </Link>
                </li>

                <li className="nav-item mobile-list-item">
                    <Link
                        to="/deals"
                        rel="noreferrer noopener nofollow"
                        className="nav-link"
                    >
                        {t('header_deals')}
                    </Link>
                </li>

                <li className="nav-item mobile-list-item">
                    <Link
                        to="/about"
                        rel="noreferrer noopener nofollow"
                        className="nav-link"
                    >
                        {t('header_about')}
                    </Link>
                </li>
            </ul>

            <ul className="nav-list mobile-list">
                <li className="nav-item mobile-list-item">
                    <Link
                        to="/news"
                        rel="noreferrer noopener nofollow"
                        className="nav-link"
                    >
                        {t('header_news')}
                    </Link>
                </li>

                <li className="nav-item mobile-list-item">
                    <div
                        onClick={handleSupportClick}
                        className="nav-link popup-link"
                    >
                        {t('header_support')}

                        <div className={`popup-content ${isSupportOpen}`}>
                            <Link
                                to="/faq"
                                rel="noreferrer noopener nofollow"
                                className='link'
                            >
                                {t('header_faq')}
                            </Link>

                            <Link
                                to="/message-us"
                                rel="noreferrer noopener nofollow"
                                className='link'
                            >
                                {t('header_message_us')}
                            </Link>
                        </div>
                    </div>
                </li>

                <li className="nav-item mobile-list-item">
                    <Link
                        to="/contact"
                        rel="noreferrer noopener nofollow"
                        className="nav-link"
                    >
                        {t('header_contact')}
                    </Link>
                </li>

                <li className="nav-item mobile-list-item">
                    <button
                        className="dark-mode-switch"
                        onClick={ToggleDarkMode}
                    >
                        {darkModeIcon}
                    </button>
                </li>
            </ul>

            <ul className="nav-list hamburger-container">
                <div
                    className="hamburger-btn"
                    onClick={hamburgerClick}
                >
                    <span className="line" />
                    <span className="line" />
                    <span className="line" />
                </div>
            </ul>
        </nav>
    </header>

    );
}

export default Header;