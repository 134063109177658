import { useEffect } from 'react';

import './notfound.scss';
import Content from './elements/content';

const NotFound = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Page Not Found';
    }, []);

    return (
        <>

        <Content/>

        </>
    );
}

export default NotFound;