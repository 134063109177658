import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import './roles.scss';

interface RoleProps {
    name: string;
    text: string;
}

const RoleItem = (props: RoleProps) => {
    const { t } = useTranslation();

    const { name, text } = props;
    const [ isRoleOpened, setIsRoleOpened ] = useState(false);
    const navigate = useNavigate();

    const UpdateRoleState = () => {
        setIsRoleOpened(!isRoleOpened);
    }

    return (
        
    <div className={`role-item ${isRoleOpened}`}>
        <div
            className="role-top"
            onClick={UpdateRoleState}
        >
            <div className="top-left">
                <span className="role-name">
                    {name}
                </span>
            </div>

            <div className="top-right">
                <button
                    className="role-toggler"
                >
                    <img
                        src="/images/arrow_white.svg"
                        alt=""
                        className='arrow'
                    />
                </button>
            </div>
        </div>

        <div className="role-bottom">
            <span className="role-text">
                {text}
            </span>

            <Link
                to="/contact"
                rel='noreferrer nofollow noopener'
                className='btn-blue small'
                onClick={(e) => {
                    e.preventDefault();
                    navigate(
                        '/contact',
                        { state: {
                            yourData: {
                                title: name + ' position',
                                topic: 2,
                            }
                        }}
                    );
                }}
            >
                {t('get_in_touch')}
            </Link>
        </div>
 
        <div className="role-bottom-line" />
    </div>

    );
}

const Roles = () => {
    const { t } = useTranslation();

    return (

    <section id="roles" className='wrapper'>
        <div className="content">
            <div className="role-grid">
                <div className="role-group">
                    <div className="role-left">
                        <h2 className="role-head">
                            {t('technology_text')}
                        </h2>
                    </div>

                    <div className="role-right">
                        <RoleItem
                            name={t('senior_animation_programmer')}
                            text={t('senior_animation_programmer_text')}
                        />

                        <RoleItem
                            name={t('senior_ai_programmer')}
                            text={t('senior_ai_programmer_text')}
                        />

                        <RoleItem
                            name={t('game_developer')}
                            text={t('game_developer_text')}
                        />
                    </div>
                </div>

                <div className="role-group">
                    <div className="role-left">
                        <h2 className="role-head">
                            {t('design_text')}
                        </h2>
                    </div>

                    <div className="role-right">
                        <RoleItem
                            name={t('gameplay_designer')}
                            text={t('gameplay_designer_text')}
                        />
                    </div>
                </div>

                <div className="role-group">
                    <div className="role-left">
                        <h2 className="role-head">
                            {t('art_and_animation')}
                        </h2>
                    </div>

                    <div className="role-right">
                        <RoleItem
                            name={t('character_artist')}
                            text={t('character_artist_text')}
                        />

                        <RoleItem
                            name={t('environment_artist')}
                            text={t('environment_artist_text')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
}

export default Roles;