import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import DivHeading from '../../components/DivHeading/DivHeading';

import './faq.scss';

import { useTranslation } from 'react-i18next';

interface Props {
    question: string;
    answer: any;
}

const Content = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const Question = (props: Props) => {
        const { question, answer } = props;
        const [ opened, setOpened ] = useState<boolean>(false);

        const HandleQuestionClick = () => {
            setOpened(!opened);
        }

        return (
            <div
                className={`question-wrapper ${opened}`}
            >
                <div
                    className={`question-top ${opened}`}
                    onClick={HandleQuestionClick}
                >
                    <div className="q-top-left">
                        <span className="q-text">
                            {question}
                        </span>
                    </div>

                    <div className="q-top-right">
                        <img
                            src={`/images/arrow_black.svg`}
                            alt=""
                            className='arrow-black'
                        />
                    </div>
                </div>

                <div className="question-bottom">
                    <span className="q-answer">
                        {answer}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <section id="faq" className="wrapper">
            <div className="content">
                <DivHeading
                    head={t('faq_head')}
                    isCentered={false}
                />

                <div className="faq-grid">
                    <div className="faq-side question-grid">
                        <Question
                            question={t('faq_q_1')}
                            answer={t('faq_a_1')}
                        />

                        <Question
                            question={t('faq_q_2')}
                            answer={t('faq_a_2')}
                        />

                        <Question
                            question={t('faq_q_3')}
                            answer={<span>{t('faq_a_3_1')}<Link
                                to="/message-us"
                                rel='noreferrer noopener nofollow' className='clean-link'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        '/message-us',
                                        { state: {
                                            yourData: {
                                                topic: 4,
                                                title: ''
                                            }
                                        }}
                                    );
                                }}
                            >{t('faq_a_3_2')}</Link>{t('faq_a_3_3')}<Link
                                to="/refund-policy"
                                rel='noreferrer noopener nofollow' className='clean-link'
                            >{t('faq_a_3_4')}</Link>{t('faq_a_3_5')}</span>}
                        />

                        <Question
                            question={t('faq_q_4')}
                            answer={<span>
                            {t('faq_a_4')}
                            <Link
                                to="/contact"
                                rel='noreferrer noopener nofollow' className='clean-link'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        '/message-us',
                                        { state: {
                                            yourData: {
                                                topic: 6,
                                                title: ''
                                            }
                                        }}
                                    );
                                }}
                            >{t('faq_a_4_1')}</Link>{t('faq_a_4_2')}<Link
                                to="/privacy-policy"
                                rel='noreferrer noopener nofollow' className='clean-link'
                            >{t('agree_privacy_policy')}</Link>{t('faq_a_3_5')}</span>}
                        />

                        <Question
                            question={t('faq_q_5')}
                            answer={t('faq_a_5')}
                        />

                        <Question
                            question={t('faq_q_6')}
                            answer={<span>{t('faq_a_6')}<Link
                                to="/contact"
                                rel='noreferrer noopener nofollow' className='clean-link'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                        '/contact',
                                        { state: {
                                            yourData: {
                                                topic: 3,
                                                title: 'Steam Game Code'
                                            }
                                        }}
                                    );
                                }}
                            >{t('faq_a_4_1')}</Link>{t('faq_a_6_1')} <a
                            href="https://store.steampowered.com/"
                            rel='noreferrer noopener nofollow' className='clean-link'
                            target='_blank'
                            >{t('faq_a_6_2')}</a>.</span>}
                        />
                    </div>

                    <div className="faq-side">
                        <img
                            src="/images/faq.svg"
                            alt=""
                            className='faq-image'
                        />
                    </div>
                </div>

                <DivHeading
                    head={t('cant_find')}
                    isCentered={false}
                />

                <Link
                    className="btn-blue small"
                    to='/message-us'
                    rel='noreferrer noopener nofollow'
                >
                    {t('cant_find_text')}
                </Link>
            </div>
        </section>
    );
}

export default Content;