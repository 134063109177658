import React, { useState, useEffect } from 'react';
import './CookiePopup.scss';

import { useTranslation } from 'react-i18next';

import { X } from 'react-bootstrap-icons';

const CookiePopup = () => {
  const [popupState, setPopupState] = useState<string>('visible');

  const { t } = useTranslation();

  useEffect(() => {
    const cookieChoice = getCookie('CookieChoice2');
    
    if (cookieChoice) {
      setPopupState('hidden');
    }
  }, []);

  const handleAccept = () => {
    setPopupState('hidden');
    setCookie('CookieChoice2', 'accepted', 365);
  };

  const handleReject = () => {
    setPopupState('hidden');
    setCookie('CookieChoice2', 'rejected', 365);
  };

  const HidePopup = () => {
    setPopupState('hidden');
  }

  const getCookie = (name: string) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim());
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  const setCookie = (name: string, value: string, days: number) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
  };

  return (
    <div className={`cookie-popup-wrapper ${popupState}`}>
      <div className="cookie-content">
        <div className="cookie-top">
          <span className="cookie-head">{t('cookie_head')}</span>
          <button 
            className="close-cookie"
            onClick={HidePopup}
          >
            <X/>
          </button>
        </div>
        <span className="cookie-text">
          {t('cookie_popup_text')}
        </span>
        <div className="cookie-buttons">
          <button className="cookie-btn filled" onClick={handleAccept}>
            {t('cookie_accept')}
          </button>
          <button className="cookie-btn" onClick={handleReject}>
            {t('cookie_reject')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
