import { useNavigate } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';

import './updates.scss';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

interface Props {
    name: string;
    date: string;
    description: string;
    image: string;
    id: number;
    changelog: React.ReactElement;
    newDate?: string;
    descName?: string;
}

const Update = (props: Props) => {
    const navigate = useNavigate();

    const {
        name,
        date,
        description,
        image,
        id,
        changelog,
        newDate,
    } = props;

    const htmlString = renderToStaticMarkup(changelog);

    const { t } = useTranslation();

    return (
        <div className="update-container">
            <div className="update-top">
                <img
                    src={`/images/games/${image}`}
                    alt=""
                    className='update-image'
                />
            </div>

            <div className="update-content">
                <div className="u-c-top">
                    <span className="update-name">
                        {name}
                    </span>

                    <span className="update-date">
                        {date}
                    </span>
                </div>

                <div className="u-c-bottom">
                    <span className="update-description">
                        {description}
                    </span>

                    <Link
                        to={`/update/${id}`}
                        className='btn-blue small'
                        rel='noreferrer noopener nofollow'
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(
                                `/update/${id}`,
                                { state: {
                                    data: {
                                        name,
                                        date,
                                        newDate,
                                        image,
                                        description,
                                        changelog: htmlString
                                    }
                                }}
                            );
                        }}
                    >
                        {t('read_more')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

const Updates = () => {
    const { t } = useTranslation();

    return (
        <section id="updates" className="content">
            <div className="content update-grid">
                <Update
                    name='Wizard Tower Hotfix'
                    description={t('wizard_tower_update_desc')}
                    date={`4 ${t('month_dec')}`}
                    newDate={`4 ${t('month_december')}`}
                    image='wizard_tower.png'
                    id={1}
                    changelog={<></>}
                />

                <Update
                    name='Rocket League 2.2'
                    description={t('rocket_league_update_desc')}
                    date={`27 ${t('month_nov')}`}
                    newDate={`27 ${t('month_november')}`}
                    image='rocket_league.jpg'
                    id={2}
                    changelog={
                    <>
                        <span className='c-l-head'>
                            {t('rl_new_cars')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('rl_new_car_1')}
                            </li>

                            <li className="c-l-l-item">
                                {t('rl_new_car_2')}
                            </li>

                            <li className="c-l-l-item">
                                {t('rl_new_car_3')}
                            </li>

                            <li className="c-l-l-item">
                                {t('rl_new_car_4')}
                            </li>
                        </ul>

                        <span className="c-l-head">
                            {t('bug_fixes')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('rl_fix_1')}
                            </li>

                            <li className="c-l-l-item">
                                {t('rl_fix_2')}
                            </li>
                        </ul>

                        <span className="c-l-head">
                            {t('rank_system_head')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('bannable_smurfing')}
                            </li>
                        </ul>
                    </>
                    }
                />

                <Update
                    name='Modern Warfare 3'
                    description={t('mw3_update_desc')}
                    date={`24 ${t('month_nov')}`}
                    newDate={`24 ${t('month_november')}`}
                    image='mw3.jfif'
                    id={3}
                    changelog={
                    <>
                        <span className="c-l-head">
                            {t('zombie_head')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('mw3_update_1')}
                            </li>
                        </ul>

                        <span className="c-l-head">
                            {t('mw3_weapon_wraps')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('mw3_weapon_wrap_update')}
                            </li>
                        </ul>
                    </>
                    }
                />

                <Update
                    name='Assassins Creed 1.05'
                    description={t('assassins_creed_update_desc')}
                    date={`23 ${t('month_nov')}`}
                    newDate={`23 ${t('month_november')}`}
                    image='assassins_creed.webp'
                    id={4}
                    changelog={
                    <>
                        <span className="c-l-head">
                            {t('assassins_new_quests')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('assassins_change_1')}
                            </li>

                            <li className="c-l-l-item">
                                {t('assassins_change_2')}
                            </li>
                        </ul>

                        <span className="c-l-head">
                            {t('assassins_map_area_head')}
                        </span>

                        <ul className="change-log-list">
                            <li className="c-l-l-item">
                                {t('assassins_map_1')}
                            </li>

                            <li className="c-l-l-item">
                                {t('assassins_map_2')}
                            </li>

                            <li className="c-l-l-item">
                                {t('assassins_map_3')}
                            </li>
                        </ul>
                    </>
                    }
                />
            </div>
        </section>
    );
}

export default Updates;