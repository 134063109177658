import { useEffect } from 'react';

import '../policies.scss';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Tos = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Terms Of Service';
        window.scrollTo(0,0);
    }, []);

    const { t } = useTranslation();

    return (
    <>
        <section className="wrapper policy-wrapper">
            <div className="content policy-content">
                <div className="policy-item">
                    <h1 className="policy-head-main">
                        {t('terms_of_service')}, {t('month_november')} 16, 2023
                    </h1>
                </div>

                <div className="policy-item">
                    <span className="policy-text white">
                        {t('tos_heading_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('acceptance_of_terms')}
                    </h2>

                    <span className="policy-text">
                        {t('acceptance_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('changes_to_terms')}
                    </h2>

                    <span className="policy-text">
                        {t('changes_to_terms_text')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        {t('tos_privacy')}
                    </h2>

                    <span className="policy-text">
                        {t('tos_privacy_text')}
                        <Link to="/privacy-policy" rel='noreferrer noopener nofollow' className='clean-link'>{t('agree_privacy_policy')}</Link>.
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        4. {t('intellectual_property')}
                    </h2>

                    <span className="policy-text">
                        {t('tos_intellectual_property')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        5. {t('prohibited_conduct')}
                    </h2>

                    <span className="policy-text">
                        {t('prohibited_conduct_text')}
                    </span>

                    <span className="policy-text">
                        {t('conduct_1')}
                    </span>

                    <span className="policy-text">
                        {t('conduct_2')}
                    </span>

                    <span className="policy-text">
                        {t('conduct_3')}
                    </span>
                </div>

                <div className="policy-item">
                    <h2 className="policy-head">
                        6. {t('limitation_of_liability')}
                    </h2>

                    <span className="policy-text">
                        {t('tos_limitation')}
                    </span>
                </div>
            </div>
        </section>
    </>
    );
}

export default Tos;