import './DivHeading.scss';

interface Props {
    head: string;
    text?: string;
    isCentered: boolean;
}

const DivHeading = (props: Props) => {
    const { head, text, isCentered } = props;

    return (
        <div className={`heading ${isCentered}`}>
            <h2 className="div-head">
                {head}
            </h2>

            {text && text.length > 0 && (
                <span className='div-head-text'>
                    {text}
                </span>
            )}

            <div className="div-heading-line" />
        </div>
    );
}

export default DivHeading;