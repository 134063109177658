import { useEffect } from 'react';
import Hero from "./elements/hero/hero";
import Roles from "./elements/roles/roles";

const Careers = () => {
    useEffect(() => {
        document.title = 'Frostcloud Interactive | Careers';
        window.scrollTo(0,0);
    }, []);

    return (

    <>

    <Hero/>

    <Roles/>

    </>

    );
}

export default Careers;