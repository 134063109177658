import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './ts/App';

import { BrowserRouter as Router } from 'react-router-dom';

import Header from "./ts/components/header/header";
import Footer from './ts/components/footer/footer';

import i18n from './i18n';
import CookiePopup from './ts/components/cookiePopup/CookiePopup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <CookiePopup/>
    <Header/>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    <Footer/>
  </Router>
);