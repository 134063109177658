import { useEffect } from "react";

import Content from './content';

const News = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
    
    <>

    <Content/>

    </>

    ); 
}

export default News;